<template>
    <!-- <div class="pages container" ref="container"></div> -->
    <div class="container">
        <div style="display: flex">
            <Button type="primary" v-if="!flag" @click="edit" style="width: 100px; height: 30px; margin-top: 30px">编辑应用</Button>
            <!-- <Button type="primary" v-if="flag" @click="save" style="width: 100px; height: 30px; margin-top: 30px">保存应用</Button> -->
            <Button type="primary" v-if="flag" @click="cancel" style="width: 100px; height: 30px; margin-top: 30px;">保存编辑</Button>
            <!-- <div v-if="flag" style="margin-top: 35px; margin-left: 20px; color: blue">
                当前已选择：
                <span v-for="(item, index) in titleArr" :key="index" style="margin-left: 6px">{{ item }}</span>
            </div> -->
        </div>
        <div style="font-size: 20px; font-weight: bold; margin-bottom: 8px; margin-top: 20px">我的快捷应用</div>
        <div style="display: flex; height: 200px; width: 100%; background: #f5f7f9; align-items: center; padding: 25px">
            <div class="item" v-for="(item, index) in myArr" :key="index">
                <Icon v-if="flag" @click="del(item)" type="ios-close-circle" style="position: relative; left: 23px; top: 8px; cursor: pointer" />
                <img style="width: 50px; height: 50px; border-radius: 8px" src="../../assets/iconcc.jpeg" />
                <div class="item-font">{{ item }}</div>
            </div>
        </div>

        <div v-for="item in menuList" :key="item.name">
            <div v-if="item.name != 'systemIndex'" class="menuTitle">{{ item.meta.title }}</div>
            <div v-if="item.name != 'systemIndex'" class="menuItem">
                <div class="item" v-for="itemName in item.children" :key="itemName.name" @click="toAdd(itemName.name, itemName.meta.title)">
                    <Icon v-if="flag" type="md-add-circle" style="position: relative; left: 23px; top: 8px; cursor: pointer" />
                    <img style="width: 50px; height: 50px; cursor: pointer; border-radius: 8px" src="../../assets/iconcc.jpeg" />
                    <div class="item-font">{{ itemName.meta.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import theme from '@/view/dataStatistics/comp/echarts/roma';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getFastapps, saveFastapps } from '@/lib/request/auth';
import { pageMenus, filterAsyncRouter } from '@/router/routes';
// import { from } from 'core-js/core/array';
echarts.registerTheme('roma', theme.roma);

export default {
    name: 'systemindex',
    data() {
        return {
            flag: false,
            titleArr: [],
            myArr: [],
            fastArr: [],
            arr: [],
            menuList: [],
            columns: [
                {
                    title: 'Name',
                    key: 'name',
                },
                {
                    title: 'Age',
                    key: 'age',
                },
                {
                    title: 'Address',
                    key: 'address',
                },
            ],
            data: [
                {
                    name: 'John Brown',
                    age: 18,
                    address: 'New York No. 1 Lake Park',
                    date: '2016-10-03',
                },
                {
                    name: 'Jim Green',
                    age: 24,
                    address: 'London No. 1 Lake Park',
                    date: '2016-10-01',
                },
                {
                    name: 'Joe Black',
                    age: 30,
                    address: 'Sydney No. 1 Lake Park',
                    date: '2016-10-02',
                },
                {
                    name: 'Jon Snow',
                    age: 26,
                    address: 'Ottawa No. 2 Lake Park',
                    date: '2016-10-04',
                },
            ],
            contextData: null,
            treeData: {
                name: 'Kinglong琪朗',
                children: [],
            },
            myChart: null,
        };
    },
    computed: {
        ...mapGetters(['getRoutes']),
        currentRoute() {
            return this.$route || {};
        },
    },
    mounted() {
        this.menuList = filterAsyncRouter(cloneDeep(pageMenus));
        this.getList();
        console.log(filterAsyncRouter(cloneDeep(pageMenus)), 'filterAsyncRouter(cloneDeep(pageMenus))');
    },
    methods: {
        del(row) {
            console.log(row, 'indexrow');
            console.log(this.fastArr, 'this.fastArr');
            console.log(this.myArr, 'this.fastArr');
            const index = this.myArr.indexOf(row);
            if (index > -1) {
                this.myArr.splice(index, 1);
                this.fastArr.splice(index, 1);
                // this.save();
            }
            saveFastapps({ fastapps: this.fastArr.join(',') }).then(res => {
                this.fadeAlert('保存成功');
                this.getList();
                // this.flag = false;
                this.titleArr = [];
            });
            console.log(index, 'index');
            // this.fastArr
        },
        cancel() {
            this.flag = false;
        },
        edit() {
            this.flag = true;
        },
        getList() {
            getFastapps().then(res => {
                if (res.data.fastapps != null) {
                    this.fastArr = res.data.fastapps.split(',');
                    console.log(this.fastArr, 'this.fastArr ');
                    let arr = [];
                    this.fastArr.forEach(item => {
                        this.menuList.forEach(itemName => {
                            if (itemName.name != 'systemIndex') {
                                console.log('666');
                                itemName.children.forEach(item1 => {
                                    if (item == item1.name) {
                                        arr.push(item1.meta.title);
                                    }
                                });
                            }
                        });
                    });
                    this.myArr = arr;
                    console.log(this.myArr, 'myArr');
                }
            });
        },
        save() {
            var arrcc = this.arr.concat(this.fastArr);
            this.arr = [...new Set(arrcc)];
            console.log(this.arr.join(','), 'this.arr');
            saveFastapps({ fastapps: this.arr.join(',') }).then(res => {
                this.fadeAlert('保存成功');
                this.getList();
                this.flag = false;
                this.titleArr = [];
            });
        },
        toAdd(name, title) {
            this.titleArr.push(title);
            this.titleArr = [...new Set(this.titleArr)];
            this.arr.push(name);
            console.log(this.arr, 'this.arr');
            var arrcc = this.arr.concat(this.fastArr);
            this.arr = [...new Set(arrcc)];
            console.log(this.arr.join(','), 'this.arr');
            saveFastapps({ fastapps: this.arr.join(',') }).then(res => {
                this.fadeAlert('保存成功');
                this.getList();
                // this.flag = false;
                this.titleArr = [];
            });

        },
        handleContextMenu(data) {
            this.contextData = data;
        },
        handleContextMenuEdit() {
            this.$Message.info('Click edit of ' + this.contextData.name);
        },
        handleContextMenuDelete() {
            this.$Message.info('Click delete of ' + this.contextData.name);
        },

        resize() {
            this.myChart && this.myChart.resize();
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    //
    display: flex;
    flex-direction: column;
}
.menuTitle {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 20px;
}
.menuItem {
    display: flex;
    height: 200px;
    width: 100%;
    background: #f5f7f9;
    align-items: center;
}
.item-font {
    margin-top: 5px;
}
.numfont {
    font-size: 23px;
    font-weight: bold;
}
.orderNum {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrap {
    background-color: rgb(219, 223, 243);
    width: 400px;
    height: 300px;
    padding: 5px;
    margin-top: 15px;
}
.wrap1 {
    background-color: rgb(219, 223, 243);
    width: 400px;
    height: 320px;
    padding: 5px;
    margin-top: 15px;
}
.wrap2 {
    background-color: rgb(219, 223, 243);
    width: 400px;
    height: 349px;
    padding: 5px;
    margin-top: 15px;
}
.item {
    // margin: 0 20px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrap-item {
    // width:500px;
    // height:300px;
    // background-color: #fff;
    // position:absolute;
    // top:15px;
}
.title {
    margin-left: 10px;
    margin-top: 5px;
    font-weight: bold;
}
</style>
