var roma = {
	color: ['#6fc6f7', '#5fb963', '#f09e5b', '#00FFFF', '#f3c96b', '#de6e6b', '#8d50b5', '#7B68EE', '#7B68EE', '#6495ED', '#1E90FF'],
	backgroundColor: 'rgba(0,0,0,0)',
	textStyle: {
		fontSize: 14,
	},
	toolbox: {
		show: false,
	},
	tooltip: {
		backgroundColor: 'rgba(0,0,0,0.65)',
		borderWidth: 0,
		// padding: [25, 40, 25, 40],
		// borderColor: '#10359b',
		textStyle: {
			fontSize: 14,
			color: '#ffffff',
		},
	},
};
export default {
	roma,
};
